<template>
	<w-dialog v-model="dialog" :max-width="$vuetify.breakpoint.xl ? '40vw' : '90vw'" :title="$t('settings.create_theme')" @close="$emit('closed')">
		<v-layout column>
			<v-layout align-center row>
				<v-flex grow pr-1>
					<v-text-field v-model="newTheme.title" type="text" :rules="[rules.title]" :label="$t('theme.theme_name')" required></v-text-field>
				</v-flex>
				<v-flex pl-1 shrink>
					<w-color-picker v-model="newTheme.color" :label="$t('theme.color_title')"/>
				</v-flex>
			</v-layout>
			<v-flex shrink>
				<v-select v-model="newTheme.theme_permission_id" :label="$t('theme.acl_title')" :items="aclList"></v-select>
			</v-flex>
			<v-flex shrink>
				<v-checkbox
					v-model="newTheme.is_shared"
					color="primary"
					:label="isAccountant ? $t('theme.actions.share_with_company') : $t('theme.actions.share_with_accounting_firm')"
					hide-details
				/>
			</v-flex>
		</v-layout>
		<template v-slot:actions>
			<w-layout align-center justify-end row>
				<w-btn-save :disabled="!isNewThemeValid" flat @click="createTheme" />
			</w-layout>
		</template>
	</w-dialog>
</template>

<script>
import CustomersManagerModuleGuard from '@/mixins/ModulesGuards/CustomersManager/CustomersManagerModuleGuard'

export default {
	name: 'CustomerThemeCreator',
	mixins: [CustomersManagerModuleGuard],
	props: {
		accountingFirm: {
			required: true,
			type: Object
		},
		company: {
			required: true,
			type: Object
		},
		isAccountant: {
			required: true,
			type: Boolean
		},
		aclList: {
			required: true,
			type: Array
		},
		themes: {
			required: true,
			type: Array
		}
	},
	data: function () {
		return {
			dialog: false,
			newTheme: {
				title: null,
				color: '#000000',
				is_shared: false,
				theme_permission_id: this.aclList[0].value
			},
			rules: {
				title: value => {
					let result = true
					if (!value || !value.trim()) {
						result = this.$t('theme.errors.title_required')
					} else if (this.themesTitles.includes(value)) {
						result = this.$t('theme.errors.title_already_exists')
					}
					return result
				}
			}
		}
	},
	computed: {
		isTitleValid: function () {
			let result = true
			if (!this.newTheme.title || !this.newTheme.title.trim()) {
				result = [this.$t('theme.errors.title_required')]
			} else if (this.themesTitles.includes(this.newTheme.title)) {
				result = [this.$t('theme.errors.already_exists')]
			}
			return result
		},
		isNewThemeValid: function () {
			return this.isTitleValid === true
		},
		themesTitles: function () {
			return this.themes.map(theme => theme.title)
		}
	},
	mounted: function () {
		this.dialog = true
	},
	methods: {
		createTheme: function () {
			let action
			if (this.isAccountant) {
				action = this.service.createThemeForAccountant(this.accountingFirm.id, this.company.id, this.newTheme)
			} else {
				action = this.service.createThemeForCompanyUser(this.company.id, this.newTheme)
			}
			action
				.then(res => {
					this.addTheme(res)
					this.appEventBus.emit(this.appEvents.SNACKBAR_SUCCESS, this.$t('settings.messages.theme_created'))
				})
				.finally(() => this.$emit('closed'))
		},
		addTheme: function (theme) {
			this.$emit('add-theme', theme)
		}
	}
}
</script>
