import AbstractContextualizedModuleGuard from '@/mixins/ModulesGuards/AbstractContextualizedModuleGuard'
import Service from '@/services/CustomersManager/CustomersManagerService'
import { Events, Bus } from '@/events/CustomersManager/CustomersManagerEvents'

export default {
	mixins: [AbstractContextualizedModuleGuard],
	data: function () {
		return {
			moduleName: 'customers',
			eventBus: Bus,
			service: Service,
			events: Events
		}
	}
}
